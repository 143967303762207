import type { TRule } from 'fela';

const dotsBg = {
    backgroundImage: 'radial-gradient(rgba(201, 201, 204, 0.5) 11%, transparent 11%)',
    backgroundPosition: '0 0, 0 0',
    backgroundSize: '1rem 1rem',
};

const theme = {
    font: {
        family: 'Soleil, Verdana, sans-serif',
    },
    typography: {
        HeadingS: {
            fontSize: '1.25rem', // 20px
            fontWeight: 700,
            lineHeight: '1.3em', // ~26px
            letterSpacing: '0.0375em', // ~ 0.75px
        },
        HeadingM: {
            fontSize: '1.75rem', // 28px,
            fontWeight: 700,
            lineHeight: '1.29em', // ~ 36px
            letterSpacing: '0.027em', // ~ 0.75px
        },
        ParagraphS: {
            fontSize: '1rem', // 16px
            lineHeight: '1.75em', // 28px
            letterSpacing: '0.04375em', // 0.7px
        },
        TextS: {
            fontSize: '0.6875rem', // 11px
            lineHeight: '1.27em', // ~ 14px
            letterSpacing: '0.045em', // ~ 0.5px
        },
        TextM: {
            fontSize: '0.875rem', // 14px
            lineHeight: '1.36em', // ~ 19px
            letterSpacing: '0.0428em', // ~ 0.6px
        },
        TextL: {
            fontSize: '1rem', // 16px
            lineHeight: '1.5em', // 24px
            letterSpacing: '0.04375em', // 0.7px
        },
    },
    colors: {
        black: '#000',
        lightBlack: 'rgba(0,0,0,0.7)',
        veryLightBlack: 'rgba(0,0,0,0.4)',
        defaultText: '#737373',
        grey: '#EBEBEB',
        lightGrey: '#BFBFBF',
        purpleGrey: '#E4E4FA',
        brownGrey: '#979797',
        grey2: '#D8D8D8',
        white: '#FFF',
        darkWhite: '#D9D9D9',
        primary: '#0000FF',
        primaryDark: '#0000C2',
        pink: '#FF00EB',
        lightPink: '#FF4FF1',
        purpleWhite: '#F8F8FF',
        red: '#F5222D',
        lightRed: 'rgba(245,34,45,0.1)',
        darkGrey: '#414141',
        brownGrey3: '#BBBBBB',
        brownGrey2: '#808080',
        brownGrey4: '#9B9B9B',

        selectedProductActiveBg: `#fff0fe`,
        activeInput: '#f8f7f6',

        google: {
            blue: '#287AED',
            red: '#F23B35',
            yellow: '#FFB32C',
            green: '#0D9E4E',
            grey: '#505459',
        },

        // New design system
        accent: {
            primary: '#0000FF',
            secondary: '#FF00EB',
            tertiary: '#2FD894',
        },
        background: {
            primary: '#FFFFFF',
            secondary: '#F8F8FF',
            tertiary: '#E4E4FA',
            gradient: 'linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FAFAFF 100%)',
            headerGradient: 'linear-gradient(228deg, #F5F5FF 0%, rgba(248, 248, 255, 0.00) 55.21%, #F5F5FF 100%)',
            banners: 'linear-gradient(90deg, #2929FF 10.95%, #5353FF 91.44%)',
        },
        foreground: {
            primary: '#2A292F',
            secondary: '#8E8C95',
            onPrimary: '#FFFFFF',
            placeholder: '#E6E6E6',
        },
        buttons: {
            primary: {
                default: '#0000FF',
                hover: '#0000C2',
                pressed: '#00008F',
                text: '#FFFFFF',
            },
            secondary: {
                default: '#2A292F',
                hover: '#0000FF',
                pressed: '#00008F',
            },
            text: {
                default: '#0000FF',
                hover: '#FF00EB',
            },
            video: {
                default: '#FF00EB',
                hover: '#FF4FF1',
                text: '#FFFFFF',
            },
            dropdown: {
                default: '#2A292F',
                hover: '#0000FF',
                activated: '#FF00EB',
            },
        },
        border: {
            cards: '#DBDAE0',
        },
        illustrations: {
            border: '#E4E4FA',
            fill: '#B2B2F1',
        },
        icons: {
            primary: '#0000FF',
            secondary: '#C0BFC9',
        },
    },
    gradients: {
        background: 'linear-gradient(180deg, #FFFFFF 0%, #FAFAFF 100%)',
    },
    shadows: {
        hero: '0px 16px 50px rgba(0, 0, 255, 0.08)',
        playButton: '-3px 8px 24px 0px rgba(178, 0, 164, 0.30)',
        bannerButton: '2px 4px 12px 0px rgba(0, 0, 255, 0.80)',
        card: '0px 0px 50px 0px rgba(0, 0, 255, 0.08)',
    },
    dotsBg,
    dotsBgBase: {
        ...dotsBg,
        position: 'absolute',
        maxWidth767: {
            display: 'none',
        },
    },
    transitions: {
        default: '1.5s cubic-bezier(0.4, 0, 0, 0.9)',
        short: '0.5s cubic-bezier(0.4, 0, 0, 0.9)',
    },
    behaviors: {
        link: {
            color: '#0000FF',

            textDecoration: 'underline',

            transition: '0.3s cubic-bezier(0.37, -0.02, 0.03, 0.89)',
            transitionProperty: 'color',

            ':hover': {
                color: '#FF00EB',
                textDecoration: 'none',
            },
        },
    },
} as const;

export default theme;

export type Theme = typeof theme;

export type TRuleWithTheme<T = {}> = TRule<
    {
        theme: Theme;
    } & T
>;
